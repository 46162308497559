import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { WindowLocation } from '@reach/router'

import Layout from '../components/layout'
import { 
    FirstSectionWrapper, 
    FirstSection, 
    FirstSectionTextWrapper, 
    FirstSectionText, 
    HeaderBar, 
    HeaderBarText, 
    HeaderBarBottom 
} from './index'
import Section from '../components/section/section'
import EquipmentItem from '../components/equipmentItem'
import Modal from '../components/modal'

const STUDIO_A_CONSOLE = [
    'SSL Duality Delta 48-Channel'
]

const STUDIO_A_MONITORING = [
    'Augspurger GA-21SHS-A3 Mains',
    'Barefoot MicroMain 27 (Gen 2)',
    'Yamaha NS10 w/ Focal CMS Sub',
    'Avantone Mixcube',
    'Bryston Amplifiers'
]

const STUDIO_A_SOFTWARE = [
    'Antares Autotune 5, Evo, 7, Live, EFX',
    'Pro Tools 12 HD',
    'Pro Tools 10 HD',
    'Celemony Melodyne',
    'VocAlign',
    'Mixed In Key',
    'Waves Mercury',
    'DDP Creator',
    'Avid HD I/O Converters 16x16 (x3)',
    'Avid HDX DSP Cards (x2)',
    'UAD-2 Octo Card',
    'Apple Mac Pro',
    'Waves', 
    'McDSP', 
    'Slate Digital', 
    'UAD', 
    'Izotope', 
    'Celemony', 
    'Antares',
    'Brainworx', 
    'Fabfilter', 
    'Avid', 
    'Lexicon'
]

const STUDIO_A_HEADPHONES = [
    'Aviom AN-16 with 5 A-16cs Personal Mixers',
    'Little Red Cue Box Headphones System (x6)'
]

const STUDIO_A_MICROPHONE_PREAMPS = [
    'Avalon VT 737-SP Channel Strip',
    'Focusrite Red 7 Channel Strip (x2)',
    'Tube-Tech MP2A Dual-Channel Tube Pre',
    'Neve 1073LB 500 Series (x4)',
    'Chandler Little Devil 500 Series (x2)'
]

const STUDIO_A_EQUALIZERS = [
    'Neve 1073LB EQ 500 Series (x2)',
    'Maag EQ4 500 Series Six-Band Equalizer w/ AIR BAND',
    'GML 8200 Dual-Channel Parametric Equalizer',
    'Retro 2A3 Dual-Channel Tube Equalizer',
    'Pultec EQP-1A3 Mono Tube Equalizer - Vintage',
    'Empirical Labs LilFreq Mono Equalizer',
    'Kush Clariphonic Dual-Channel Parallel Equalizer'
]

const STUDIO_A_COMPRESSORS = [
    'Tube-Tech CL-1B Tube Compressor',
    'Universal Audio LA-2A Leveling Amplifier',
    'Universal Audio 1176LN Limiting Amplifier (x2) - paired',
    'Neve 33609 JD Stereo Compressor',
    'Empirical Labs EL8X Distressor (x4) - 2 pair',
    'Standard Audio Level-Or 500 Series JFET limiter',
    'InnerTUBE Audio Atomic Squeezebox Mono Tube Compressor',
    'dbx 165 Compressors (x2) - Vintage',
    'Elysia MPressor Two-Channel Compressor'
]

const STUDIO_A_REVERB = [
    'Bricasti Design M7 - with remote'
]

const STUDIO_A_MISC = [
    'Elysia Nvelope Stereo Impulse Shaper',
    'Empirical Labs EL-DS DERRESSER 500 Series De-esser'
]

const STUDIO_B_CONSOLE = [
    'Raven MTX'
]

const STUDIO_B_SOFTWARE = [
    'Avid HD I/O Converters 16x16',
    'Avid HDX',
    'Pro Tools 12HD',
    'UAD-2 Octo Card',
    'Waves', 
    'McDSP', 
    'Slate Digital', 
    'UAD', 
    'Izotope', 
    'Celemony', 
    'Antares',
    'Brainworx', 
    'Fabfilter', 
    'Avid', 
    'Lexicon'
]

const STUDIO_B_HEADPHONES = [
    'Little Red Cue Box Headphone System'
]

const STUDIO_B_EQUALIZERS = [
    'API 550A 3 band EQ (x2)',
    'API 560 Graphic EQ (x2)'
]

const STUDIO_B_MONITORING = [
    'Augspurger G-21SHS-A3 Mains',
    'Barefoot MicroMain 27 (Gen 2)',
    'Yamaha NS10',
    'Auratone Mixcube',
    'SPL 2Control Stereo Monitor Controller',
    'Bryston Amplifiers'
]

const STUDIO_B_MICROPHONE_PREAMPS = [
    'SSL XLogic 4 Channel VHD Preamp (x2)',
    'Tubetech MP1a Dual Channel Mic Preamp',
    'Neve 1073LB 500 Series Mic Preamp (x2)',
    'Focusrite RED 1 500 Series Mic Preamp (x2)',
    'Focusrite RED 7 Channel Strip'
]

const STUDIO_B_COMPRESSORS = [
    'Neve 2264a 500 Series Compressor (x2)',
    'Retro Sta-Level Gold Edition Compressor',
    'Universal Audio 1176 Compressor',
    'Universal Audio Teletronix LA2A Compressor',
    'Tube-Tech CL 1B Compressor',
    'Empirical Labs EL8X Distressor Compressor (x4)'
]

const STUDIO_B_MISC = [
    'TASCAM CD-500 CD Player',
    'Radial JD6 6 Channel Rack DI',
    'Moog Ladder Filter 500 Series (x2)'
]

const SHARED_MICROPHONES = [
    'Sony C800g (x2)',
    'AKG C12VR',
    'Korby KAT Convertible',
    'Neumann U87 Ai (x2)',
    'Neumann TLM 170 R (x2)',
    'Neumann KM184 (x2)',
    'AKG 414 XL2 (x2)',
    'Royer 121 (x2)',
    'Audio Technica 4050 (x2)',
    'RCA 44BX (Vintage)',
    'Crown PZM-6D (x2)',
    'Shure SM7B (x2)',
    'Shure SM57 (x6)',
    'Sennheiser MD 421 (x5)',
    'AKG D112 (x2)',
    'Sennheiser e609',
    'Yamaha Sub Kick',
    'Shure 556B (Vintage)',
    'Shure Super 55'
]

const SHARED_DRUMS = [
    'DW Collectors Series 5 Piece Drumkit',
    'Zildjian A Custom Cymbals',
    'Ludwig Black Beauty Supra Phonic Snare Drum',
    'Brady Sheoak Block Snare Drum',
    'Pearl Piccolo Snare Drum'
]

const SHARED_GUITARS = [
    'Gibson 2014 Les Paul Custom Maple Top ‘60s Slim Neck Electric Guitar',
    'Fender American Stratocaster Electric Guitar',
    'ESP LTD 7-String Ken Susi Signature Electric Guitar',
    'Martin D-28 Acoustic Guitar',
    'Taylor 814ce Acoustic Guitar'
]

const SHARED_GUITAR_AMPS = [
    'Kemper Profiler Rack Rackmount Guitar Preamplifier',
    'Vox AC30 30-watt 2x12” Tube Combo',
    'Peavy 5150 mkIII 50watt w/ 4x12” Egnator Cab'
]

const SHARED_BASS_GUITARS = [
    'Fender Jazz 4-string Electric Bass',
    'Ernie Ball Music Man Stingray 5-String Electric Bass',
    'Ernie Ball Music Man Bongo 5-String Electric Bass'
]

const SHARED_KEYBOARDS = [
    'Korg Microkorg Synthesizer and Vocoder',
    'Roland VP-03 Synthesizer and Vocoder',
    'M-Audio Axiom Pro 61-Key Midi Controller Keyboard',
    'Akai Advance 49-Key Midi Controller Keyboard',
    'Native Instruments Maschine 2',
    'Roland MV8000'
]

const SHARED_SOFTWARE = [
    'Spectrasonics Keyscape',
    'Garritan Pipe Organs'
]

const SHARED_MISC = [
    'Little Labs Red Eye 3D Phatom Reamp/Direct Boxes',
    'Monster cabling'
]

const StudioSection = styled(Section)`
    width: 100%;
    margin: 0;
    padding: 20px;
    justify-content: space-between;

    @media(max-width: 1024px) {
        flex-wrap: wrap;
    }
`

const StudioMainImage = styled.img`
    height: 100vh;
    max-height: calc(74vh * 9/16);
    object-fit: contain;
    box-shadow: 0px 2px 10px -2px black;
    border-radius: 4px;

    @media(max-width: 1024px) {
        max-width: 90vw;
        max-height: calc(100vw * 9/16);
        margin: 0 auto 20px auto;
    }
`

const StudioMainText = styled.p`
    font-size: 1rem;
    color: black;
    margin-left: 20px;

    @media(max-width: 1024px) {
        margin-left: 0;
        margin-top: 20px;
    }
`

const StudioSubImagesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 0 auto;

    @media(max-width: 768px) {
        grid-template-columns: unset;
        grid-template-rows: repeat(3, 1fr);
    }
`

const StudioSubImage = styled.img`
    cursor: pointer;
    box-shadow: 0px 2px 10px -2px black;
    border-radius: 4px;
    max-height: calc(50vh * 9/16);

    &:hover {
        box-shadow: 0px 2px 10px 0px black;
    }
`

const EquipmentTitle = styled.p`
    font-size: 1.5rem;
    color: black;
    font-weight: 500;
`

type Props = {
    location: WindowLocation
}

type State = {
    clickedImage: any
}

export default class Studios extends PureComponent<Props, State> {
    state = {
        clickedImage: null
    }

    imageClick = (e) => {
        console.log('CLICKED IMAGE', e.currentTarget)
        this.setState({
            clickedImage: e.currentTarget.src ? e.currentTarget.src : null
        })
    } 

    render() {
        const modalizedImage = this.state.clickedImage ? (
            <Modal handleClose={this.imageClick}>
                <img 
                    src={this.state.clickedImage!}
                    style={{
                        maxHeight: '90vh',
                        maxWidth: '90vw',
                        borderRadius: '4px'
                    }} />
            </Modal>
        ) : null

        return(
            <Layout location={this.props.location}>
                {modalizedImage}
                <FirstSectionWrapper>
                    <FirstSection src={require('../images/studio-lounge.jpg')} alt="mic"/>
                    <FirstSectionTextWrapper>
                        <FirstSectionText top={120}>STUDIOS</FirstSectionText>
                    </FirstSectionTextWrapper>
                </FirstSectionWrapper>
                <HeaderBar id="studio-a">
                    <HeaderBarText>Studio A</HeaderBarText>
                    <HeaderBarBottom/>
                </HeaderBar>
                <Section
                    width="100%"
                    margin="0"
                    padding="20px"
                    flexDirection="column">
                    <StudioSection>
                        <StudioMainImage src={require('../images/studio-a/studio-a-control-room.jpg')} alt="studio a"/>
                        <StudioMainText>
                            Studio A encompasses the control room, large
                            live room, two isolation booths, as well as an
                            isolation closet for guitar cabinets. The centerpiece
                            of the control room is a 48-Channel SSL Duality
                            Delta Console. Top-shelf monitoring is provided
                            by Augspurger and Barefoot. This incredible
                            sounding room features cloud and rear diffusion
                            and has been tuned by Ross Alexander himself.
                            Studio comforts include leather seating, wireless
                            talkback, and windows — a feature rarely seen in
                            many studios!
                        </StudioMainText>
                    </StudioSection>
                    <Section
                        width="100%"
                        margin="0 auto"
                        padding="20px">
                        <StudioSubImagesWrapper>
                            <StudioSubImage src={require('../images/studio-a/studio-a-booth.jpg')} alt="studio a image one" onClick={this.imageClick}/>
                            <StudioSubImage src={require('../images/studio-a/studio-a-console-2.jpg')} alt="studio a image one" onClick={this.imageClick}/>
                            <StudioSubImage src={require('../images/studio-a/studio-a-rack.jpg')} alt="studio a image one" onClick={this.imageClick} />
                        </StudioSubImagesWrapper>
                    </Section>
                </Section>
                <Section 
                    width="100%" 
                    margin="0" 
                    background="whitesmoke"
                    padding="20px"
                    flexDirection="column">
                    <EquipmentTitle>Equipment<br><br>Strange Land</EquipmentTitle>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/compressor.png')}
                            alt="compressors" 
                            title="Compressors" 
                            imageWidth="80%"
                            items={STUDIO_A_COMPRESSORS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/console.png')}
                            alt="console" 
                            title="Consoles" 
                            imageWidth="80%"
                            items={STUDIO_A_CONSOLE}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/equalizer.png')}
                            alt="equalizers" 
                            title="Equalizers" 
                            imageWidth="90%"
                            items={STUDIO_A_EQUALIZERS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/headphones.png')} 
                            alt="headphones" 
                            title="Headphones" 
                            imageHeight="80%"
                            items={STUDIO_A_HEADPHONES}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/preamp.png')} 
                            alt="microphone preamp" 
                            title="Microphone Preamps" 
                            imageWidth="80%"
                            items={STUDIO_A_MICROPHONE_PREAMPS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/monitoring.png')} 
                            alt="monitors" 
                            title="Monitors" 
                            imageHeight="70%"
                            items={STUDIO_A_MONITORING}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/reverb.png')} 
                            alt="reverb" 
                            title="Reverb" 
                            imageWidth="80%"
                            items={STUDIO_A_REVERB}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/software.png')} 
                            alt="software" 
                            title="Software" 
                            imageWidth="80%"
                            items={STUDIO_A_SOFTWARE}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/misc.png')} 
                            alt="miscellaneous" 
                            title="Miscellaneous" 
                            imageWidth="80%"
                            items={STUDIO_A_MISC}/>
                    </div>
                </Section>
                <HeaderBar id="studio-b">
                    <HeaderBarText>Studio B</HeaderBarText>
                    <HeaderBarBottom/>
                </HeaderBar>
                <Section
                    width="100%"
                    margin="0"
                    padding="20px"
                    flexDirection="column">
                    <StudioSection>
                        <StudioMainImage src={require('../images/studio-b/studio-b-control-room-3.jpg')} alt="studio a"/>
                        <StudioMainText>
                            Studio B features a large isolation booth, easily
                            large enough to accommodate several performers
                            at once. At the center of the control room sits a
                            46” Slate Raven Touch-Screen Monitor and Control
                            Surface. This room also features world-class,
                            expertly-tuned Augspurger and Barefoot monitoring.
                            Similar to Studio A, Studio B also has spacious
                            seating, wireless talkback, and large windows.
                            Powerhouse Pro Tools HD systems provide ample
                            resources to accommodate the largest of sessions.
                            Our enormous plugin selection contains the most
                            recognized companies in the field, such as Waves,
                            Antares, UAD, McDSP, and FabFilter.
                        </StudioMainText>
                    </StudioSection>
                    <Section
                        width="100%"
                        padding="20px">
                        <StudioSubImagesWrapper>
                            <StudioSubImage src={require('../images/studio-b/studio-b-booth.jpg')} alt="studio a image one" onClick={this.imageClick}/>
                            <StudioSubImage src={require('../images/studio-b/studio-b-preamps.jpg')} alt="studio a image one" onClick={this.imageClick}/>
                            <StudioSubImage src={require('../images/studio-b/studio-b-rack.jpg')} alt="studio a image one" onClick={this.imageClick}/>
                        </StudioSubImagesWrapper>
                    </Section>
                </Section>
                <Section 
                    width="100%" 
                    margin="0" 
                    background="whitesmoke"
                    padding="20px"
                    flexDirection="column">
                    <EquipmentTitle>Equipment<br><br>Strange Land</EquipmentTitle>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/compressor.png')}
                            alt="compressors" 
                            title="Compressors" 
                            imageWidth="80%"
                            items={STUDIO_B_COMPRESSORS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/console.png')}
                            alt="console" 
                            title="Consoles" 
                            imageWidth="80%"
                            items={STUDIO_B_CONSOLE}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/equalizer.png')}
                            alt="equalizers" 
                            title="Equalizers" 
                            imageWidth="90%"
                            items={STUDIO_B_EQUALIZERS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/headphones.png')} 
                            alt="headphones" 
                            title="Headphones" 
                            imageHeight="80%"
                            items={STUDIO_B_HEADPHONES}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/preamp.png')} 
                            alt="microphone preamp" 
                            title="Microphone Preamps" 
                            imageWidth="80%"
                            items={STUDIO_B_MICROPHONE_PREAMPS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/monitoring.png')} 
                            alt="monitors" 
                            title="Monitors" 
                            imageHeight="70%"
                            items={STUDIO_B_MONITORING}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/software.png')} 
                            alt="software" 
                            title="Software" 
                            imageWidth="80%"
                            items={STUDIO_B_SOFTWARE}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/misc.png')} 
                            alt="miscellaneous" 
                            title="Miscellaneous" 
                            imageWidth="80%"
                            items={STUDIO_B_MISC}/>
                    </div>
                </Section>
                <HeaderBar>
                    <HeaderBarText>Shared Equipment</HeaderBarText>
                    <HeaderBarBottom/>
                </HeaderBar>
                <Section 
                    width="100%" 
                    margin="0" 
                    background="whitesmoke"
                    padding="20px"
                    flexDirection="column">
                    <EquipmentTitle>Equipment<br><br>Strange Land</EquipmentTitle>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/bass.png')}
                            alt="bass guitars" 
                            title="Basses" 
                            imageHeight="90%"
                            items={SHARED_BASS_GUITARS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/guitar.png')}
                            alt="guitars" 
                            title="Guitars" 
                            imageHeight="90%"
                            items={SHARED_GUITARS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/amp.png')}
                            alt="amps" 
                            title="Guitar Amps" 
                            imageHeight="70%"
                            items={SHARED_GUITAR_AMPS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/drums.png')} 
                            alt="drums" 
                            title="Drums" 
                            imageWidth="70%"
                            items={SHARED_DRUMS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/keyboards.png')} 
                            alt="keyboards" 
                            title="Keyboards" 
                            imageWidth="80%"
                            items={SHARED_KEYBOARDS}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/microphone.png')} 
                            alt="microphones" 
                            title="Microphones" 
                            imageHeight="70%"
                            items={SHARED_MICROPHONES}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/software.png')} 
                            alt="software" 
                            title="Software" 
                            imageWidth="80%"
                            items={SHARED_SOFTWARE}/>
                        <EquipmentItem 
                            imgSrc={require('../images/equipment/misc.png')} 
                            alt="miscellaneous" 
                            title="Miscellaneous" 
                            imageWidth="80%"
                            items={SHARED_MISC}/>
                    </div>
                </Section>
            </Layout>
        )
    }
}